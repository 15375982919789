import React from "react"
import styled from 'styled-components'
import classNames from 'classnames';

import Layout from "../components/layout"
import SEO from "../components/seo"
import Arrow, { Positions } from '../components/home/arrow';

const IndexWrapper = styled.div`
  &.index-wrapper a {
    transition: top 2s;
  }

  &.index-wrapper--menu-open {
    height: calc(100vh - 192px - 30px - 48px);
    overflow: hidden;

    & .home-section > :not(.home-section__scroll-bottom) {
      filter: blur(5px);
    }

    .home-section__scroll-bottom {
      position: absolute;
      top: 10px;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 0 10%;
    margin-top: 114px;
    margin-bottom: 80px;
  }
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;

  text-align: center;
  width: 100%;

  .section__header {
    font-size: 4rem;
    font-weight: 300;
    font-family: 'Open Sans', sans-serif;
    margin: 0;
  }

  .section__header--bold {
    font-weight: bold;
  }

  .section__body {
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
  }

  &#welcome-section {
    margin-top: 120px;

    & .section__body {
      width: 40%;
      max-width: 90%;
      margin-bottom: 96px;

      & p {
        margin: 0;
      }
    }
  }

  &#about-us {
    & .section__header {
      margin-top: 42px;
    }

    & .section__body {
      width: 80%;
      max-width: 1440px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 90px;

      p {
        flex: 1 1 auto;
      }
    }
  }

  @media screen and (max-width: 768px) {

    &#welcome-section {
      padding-top: 80px;
      margin-top: 0;
      margin-bottom: 65px;

      .section__body {
        width: 100%;
        max-width: 100%;
        margin-bottom: 0;
      }

      h2 {
        width: 100%;
        font-size: 3rem;
      }
    }

    &#about-us {
      & .section__body {
        display: flex;
        flex-direction: column;
        width: 100%;
        text-align: left;
      }

      h2 {
        font-size: 3rem;
      }
    }

    p {
      width: 100%;
    }
  }
`;

const TitleSeparator = styled.span`
  width: 56px;
  border-top: 4px solid white;
  margin-top: 23px;
  margin-bottom: 48px;
`

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <IndexWrapper className={
          classNames(
            'index-wrapper',
          )
        }
      >
        <Section id='welcome-section'>
          <h2 className='section__header'>
            Welcome to<br/><span className='section__header--bold'>.aftermath</span>
          </h2>
          <TitleSeparator />
          <div className='section__body'>
            <p>
              .aftermath is a consulting, education and solution provider that combines cutting edge academic knowledge with extensive industry expertise. We focus our work on a wide range of sectors such as finance, construction, and luxury retail. In addition to our client specific work we produce analytical products and services. Please refer to the Consulting Expertise, Research or Replica sections to find out more.
            </p>
          </div>
        </Section>
        <Section id='about-us'>
          <Arrow position={ Positions.DOWN } />
          <h2 className='section__header'>
            about <span className='section__header--bold'>us</span>
          </h2>
          <TitleSeparator />
          <div className='section__body'>
            <p>
              .aftermath believes that a one-sided approach to complexity and uncertainty will no longer suffice to understand this rapidly changing world. A consistent yet empirically driven approach to address it requires a multidisciplinary, research-based and industry competitive focus that permanently allows revising and upgrading the available knowledge regarding a particular matter. To that end, academics from multiple backgrounds and industry experts have joined forces to meaningfully support your efforts and decision making processes.
              <br/><br/>
              .aftermath follows a unique consulting approach that promotes the interaction of the consultant, the client and the academic researcher. We address our projects according to the limits of our knowledge and try to go as deep into the matter as conceptually feasible but letting pragmatism drive our work.
            </p>
            <p>
              This approach allows to swiftly trace complex issues back to theoretical grounds, benchmark existing empirical evidence and develop analytical instruments fit for the purpose of the analysis.
              <br/><br/>
              We are specialized in both:  theoretical model-based analysis and active industry consulting. We do this across a vast range of industries such as banking, gaming, energy, construction, luxury retail and biomedicine. Our academic and consulting experience are valuable assets to easily communicate our work to the client as well as to contribute in the further education and  training of our clients. Our team consists of financial, real estate, luxury goods, gaming, energy, building materials and biomedical industry experts with at least 10 years of experience.
            </p>
          </div>
        </Section>
      </IndexWrapper>
    </Layout>
  );
}

export default IndexPage
