import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

import Colors from '../../colors';

// TODO: transform to svg

const StyledArrow = styled.span`
  background: transparent;
  border: none;
  outline: none;
  z-index: -1;

  .arrow__icon {
    border: solid ${ Colors.primary };
    border-width: 0 3px 3px 0;
    cursor: pointer;
    display: inline-block;
    padding: 5px;
    transition: transform 0.3s;
  }

  .arrow__icon--right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }

  .arrow__icon--left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }

  .arrow__icon--up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }

  .arrow__icon--down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
`;

export const Positions = {
  UP: 'up',
  DOWN: 'down',
  LEFT: 'left',
  RIGHT: 'right'
};

const Arrow = (props) => {
  return (
    <StyledArrow
        onClick={ props.onClick }
        className='arrow__container'>
      <span
        className={ classNames(
          'arrow__icon',
          { 'arrow__icon--up': props.position === Positions.UP },
          { 'arrow__icon--down': props.position === Positions.DOWN },
          { 'arrow__icon--left': props.position === Positions.LEFT },
          { 'arrow__icon--right': props.position === Positions.RIGHT }
        ) }
      />
    </StyledArrow>
  );
};

export default Arrow;
